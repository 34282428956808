<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>

    <template>
      <section class="section section-localnav">
        <nav class="tabnav">
          <ul class="tabnav-items">
            <li class="tabnav-offset-item"><div></div></li>
            <li class="tabnav-item tabnav-first-item">
              <em></em>
              <span class="tabnav-link" v-if="$i18n.locale !== 'en'">{{
                cat.title
              }}</span>
              <span
                class="tabnav-link"
                v-else-if="cat.translations[$i18n.locale]"
                >{{ cat.translations[$i18n.locale].title }}</span
              >
              <span class="tabnav-link" v-else>{{ cat.title }}</span>
            </li>

            <li class="tabnav-offset-item"><div></div></li>
          </ul>
        </nav>
      </section>

      <section class="section section-tiles shop-tiles">
        <div class="container m-container-only-s">
          <ul>
            <li
              :class="
                cat.code === 'alcoholic_beverages' ? 'shop-tiles-logo' : ''
              "
              v-for="item in cat.goods.filter((good, ix) => good.volume)"
              :key="item.product_id"
            >
              <figure
                @click.prevent="showItem(item, category)"
                :style="{
                  'background-image': `url(${item.image})`,
                  cursor:
                    item.description && item.description[this.$i18n.locale]
                      ? 'default'
                      : 'pointer'
                }"
              ></figure>
              <p>{{ item.title }}</p>
              <div class="purchase">
                <strong
                  ><template
                    v-if="
                      item.price.position && item.price.position === 'start'
                    "
                    >{{ item.price.currency }}</template
                  >
                  {{ item.price.amount }}
                  <template
                    v-if="item.price.position && item.price.position === 'end'"
                    >{{ item.price.currency }}</template
                  >
                  <template v-else>₽</template></strong
                >
                <a
                  class="basket"
                  href="#"
                  v-if="!card.find(it => it.productId === item.product_id)"
                  @click.prevent="
                    changeAmount({
                      category: cat.code,
                      productId: item.product_id,
                      action: 'ADD_TO_CARD'
                    })
                  "
                ></a>
                <p class="amount" v-else>
                  <span
                    class="minus"
                    @click.prevent="
                      changeAmount({
                        productId: item.product_id,
                        action: 'DECREMENT_AMOUNT_CARD'
                      })
                    "
                  ></span>
                  <b>{{
                    card.find(it => it.productId === item.product_id).amount
                  }}</b>
                  <span
                    class="plus"
                    :class="{
                      disabled:
                        parseInt(item.volume) <=
                        parseInt(
                          card.find(it => it.productId === item.product_id)
                            .amount
                        )
                    }"
                    @click.prevent="
                      changeAmount({
                        productId: item.product_id,
                        action: 'INCREMENT_AMOUNT_CARD'
                      })
                    "
                  ></span>
                </p>
                <span
                  class="amount-sm"
                  @click.prevent="openModal(item)"
                  v-if="
                    card.find(it => it.productId === item.product_id) &&
                      card.find(it => it.productId === item.product_id).amount
                  "
                  >{{
                    card.find(it => it.productId === item.product_id) &&
                      card.find(it => it.productId === item.product_id).amount
                  }}</span
                >
              </div>
            </li>
          </ul>
        </div>
      </section>
    </template>
    <amount-shop
      :item="modalItem"
      v-if="Object.keys(modalItem).length"
      @close="modalItem = {}"
    ></amount-shop>
  </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AmountShop from "@/components/popup/AmountShop";

export default {
  name: "ShopCategory",
  components: { AmountShop },
  props: ["category"],
  title() {
    return this.$t("pages.shop");
  },
  data: () => {
    return {
      modalItem: {}
    };
  },
  computed: {
    ...mapState("shop", {
      catalog: state => state.index,
      card: state => state.card
    }),
    cat() {
      return this.catalog[this.category];
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.actionIndex();
    });
  },
  methods: {
    ...mapActions("shop", {
      actionIndex: "index",
      changeAmount: "changeAmount"
    }),
    openModal(item) {
      this.modalItem = item;
    },
    showItem(item, category) {
      this.$router.push({
        name: "ShopItem",
        params: {
          category: category,
          product: item.product_id
        }
      });
      // if (item.description && item.description[this.$i18n.locale]) {
      //   this.$router.push({
      //     name: "ShopItem",
      //     params: {
      //       category: category,
      //       product: item.product_id
      //     }
      //   });
      // } else {
      //   this.changeAmount({ productId: item.productId, action: "ADD_TO_CARD" });
      // }
    }
  }
};
</script>

<style scoped>
.disabled {
  opacity: 0.4;
}
</style>
