var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"role":"main"}},[_c('meta-title',{attrs:{"title":_vm.metaTitle}}),[_c('section',{staticClass:"section section-localnav"},[_c('nav',{staticClass:"tabnav"},[_c('ul',{staticClass:"tabnav-items"},[_vm._m(0),_c('li',{staticClass:"tabnav-item tabnav-first-item"},[_c('em'),(_vm.$i18n.locale !== 'en')?_c('span',{staticClass:"tabnav-link"},[_vm._v(_vm._s(_vm.cat.title))]):(_vm.cat.translations[_vm.$i18n.locale])?_c('span',{staticClass:"tabnav-link"},[_vm._v(_vm._s(_vm.cat.translations[_vm.$i18n.locale].title))]):_c('span',{staticClass:"tabnav-link"},[_vm._v(_vm._s(_vm.cat.title))])]),_vm._m(1)])])]),_c('section',{staticClass:"section section-tiles shop-tiles"},[_c('div',{staticClass:"container m-container-only-s"},[_c('ul',_vm._l((_vm.cat.goods.filter(function (good, ix) { return good.volume; })),function(item){return _c('li',{key:item.product_id,class:_vm.cat.code === 'alcoholic_beverages' ? 'shop-tiles-logo' : ''},[_c('figure',{style:({
                'background-image': ("url(" + (item.image) + ")"),
                cursor:
                  item.description && item.description[this.$i18n.locale]
                    ? 'default'
                    : 'pointer'
              }),on:{"click":function($event){$event.preventDefault();return _vm.showItem(item, _vm.category)}}}),_c('p',[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"purchase"},[_c('strong',[(
                    item.price.position && item.price.position === 'start'
                  )?[_vm._v(_vm._s(item.price.currency))]:_vm._e(),_vm._v(" "+_vm._s(item.price.amount)+" "),(item.price.position && item.price.position === 'end')?[_vm._v(_vm._s(item.price.currency))]:[_vm._v("₽")]],2),(!_vm.card.find(function (it) { return it.productId === item.product_id; }))?_c('a',{staticClass:"basket",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.changeAmount({
                    category: _vm.cat.code,
                    productId: item.product_id,
                    action: 'ADD_TO_CARD'
                  })}}}):_c('p',{staticClass:"amount"},[_c('span',{staticClass:"minus",on:{"click":function($event){$event.preventDefault();return _vm.changeAmount({
                      productId: item.product_id,
                      action: 'DECREMENT_AMOUNT_CARD'
                    })}}}),_c('b',[_vm._v(_vm._s(_vm.card.find(function (it) { return it.productId === item.product_id; }).amount))]),_c('span',{staticClass:"plus",class:{
                    disabled:
                      parseInt(item.volume) <=
                      parseInt(
                        _vm.card.find(function (it) { return it.productId === item.product_id; })
                          .amount
                      )
                  },on:{"click":function($event){$event.preventDefault();return _vm.changeAmount({
                      productId: item.product_id,
                      action: 'INCREMENT_AMOUNT_CARD'
                    })}}})]),(
                  _vm.card.find(function (it) { return it.productId === item.product_id; }) &&
                    _vm.card.find(function (it) { return it.productId === item.product_id; }).amount
                )?_c('span',{staticClass:"amount-sm",on:{"click":function($event){$event.preventDefault();return _vm.openModal(item)}}},[_vm._v(_vm._s(_vm.card.find(function (it) { return it.productId === item.product_id; }) && _vm.card.find(function (it) { return it.productId === item.product_id; }).amount))]):_vm._e()])])}),0)])])],(Object.keys(_vm.modalItem).length)?_c('amount-shop',{attrs:{"item":_vm.modalItem},on:{"close":function($event){_vm.modalItem = {}}}}):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"tabnav-offset-item"},[_c('div')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"tabnav-offset-item"},[_c('div')])}]

export { render, staticRenderFns }